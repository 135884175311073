
import './App.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {ComingSoonLandingPage} from './ComingSoonLandingPage/ComingSoonLandingPage';

function App() {
  return (
    
      <Router> 
        
        <div className="App">
          <Switch>
            <Route path="/">
              <ComingSoonLandingPage/>
            </Route>
          </Switch>
        </div>
      </Router>
  );
}

export default App;
