import './ComingSoonLandingPage.css';
import WGLogo from '../assets/WGLogo.png';

export const ComingSoonLandingPage = () => {

  return (
    <div>
      
      <header className="comingSoonLandingPageHeader">
        <img src={WGLogo} className="WGLogo" alt="wriggle and giggle logo" width={300} />
          <h1>Online store coming soon</h1>
          <h4>Can't wait, purchase&nbsp;<a className="WGAmazonLink" href="https://www.amazon.com/"> 
            here 
            </a>
            
          </h4>
            
          
      </header>
    </div>
  );
}





